// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Tailwinds
// import '../css/marketing.css'

// require('src/sdk.js')

require("@rails/ujs").start()
require("@hotwired/turbo-rails")
global.Turbo = Turbo;
require("@rails/activestorage").start()
// require("channels")

// require("agora-rtc-sdk-ng")
// import AgoraRTC from 'agora-rtc-sdk-ng';
// global.AgoraRTC = AgoraRTC;
require("dexie")
require('jquery')
// import 'controllers'

require('src/finish_recordings.js')
require("stylesheets/marketing.css")

import LocalDB from '../src/finish_recordings'
global.local_db = new LocalDB

$( document ).on('turbo:load', function() {
  local_db.load_local_db()
  local_db.check_for_recordings_to_upload()
})

import Dexie from "dexie";
global.Dexie = Dexie

// require("recordrtc")
// import RecordRTC from 'recordrtc';
// global.RecordRTC = RecordRTC;

require('script-loader!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
require('script-loader!blueimp-file-upload/js/jquery.fileupload.js');

import Rails from '@rails/ujs';
global.Rails = Rails;

// import { DirectUpload } from "@rails/activestorage"
// global.DirectUpload = DirectUpload


String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

String.prototype.toHHMMSSMS = function() {
  var hours, milli_seconds, minutes, sec_num, seconds;
  sec_num = parseInt(this, 10);
  hours = Math.floor(sec_num / 3600);
  minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  seconds = sec_num - (hours * 3600) - (minutes * 60);
  if (this.indexOf('.') === -1) {
    milli_seconds = 0;
  } else {
    milli_seconds = Math.round((60 * parseInt(this.split('.')[1].substring(0, 2))) / 100);
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (milli_seconds < 10) {
    milli_seconds = '0' + milli_seconds;
  }
  return hours + ':' + minutes + ':' + seconds + ':' + milli_seconds;
};

String.prototype.toSeconds = function() {
  var seconds, time, time_array;
  time_array = this.split(':');
  seconds = (+time_array[0]) * 60 * 60 + (+time_array[1]) * 60 + (+time_array[2]);
  time = seconds + (time_array[3] ? time_array[3] / 60 : 0);
  return seconds + (time_array[3] ? time_array[3] / 60 : 0);
};

$.expr[':'].contains = $.expr.createPseudo(function(arg) {
  return function(elem) {
    return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
  };
});

$(document).on('click', '.toggle_mobile_menu', function(e) {
  e.preventDefault()
  $("#mobile_menu").toggle()
});

$(document).on('turbo:load', function() {

  $("#personal_dropdown_button").click(function(){
    $("#personal_dropdown").toggle()
  });
  $("body").off( "click").click(function(event){
    var opening_popup = $(event.target).hasClass("open_popup") || $(event.target).parents(".open_popup").length == 1
    var clicking_on_popup = $(event.target).parents(".popup_opened").length > 0
    if (!opening_popup && !clicking_on_popup){
      $(".popup_opened").hide()
    }
    if (!$(event.target).hasClass("user_button")){
      $(".user_options").hide()
    }
    if (!$(event.target).hasClass("edit_options")){
      $(".options").hide()
    }
  });
});

$(document).on('click', '.row, .older_row', function(event) {
  if ($(this).attr("data-href").length > 0 && $(event.target).attr("href") == undefined ){
    if ($(this).attr("data-new-tab")){
      window.open($(this).attr("data-href"), '_blank');
    } else{
      console.log("navigating to this href1")
      Turbo.visit($(this).attr("data-href"))
    }
  }
});
$(document).on('click', '.modal_background', function(event) {
  $(".modal").hide()
});
$(document).on('click', '.dismiss_notification', function(event) {
    event.preventDefault()
    $(".notification").hide()
});


window.jQuery = $;
window.$ = $;
