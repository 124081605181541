export default class LocalDB {

  constructor() {
       console.log('localdb construct')
       this.running = false;
   }

   load_local_db() {
    console.log("load db")
    var db = new Dexie("Recordings");
    db.version(2).stores({
      recordings: "++id,recording_id" ,
      blobs: "++id,recording_id,filename,attendee_id"
    });
    global.db = db
  }

   async check_for_recordings_to_upload(){
     local_db.running = true;
     // console.log("check recordings to upload")

    // //this doesnt seem correct
    db.recordings.toArray().then (async function(recordings){
      var recording = recordings[0]
      // window.uploading is used to show that recording is oging on
      if ($("[data-upload-setting]").attr("data-upload-setting") == "after" && window.uploading == true){
        console.log("WAIT UNTIL AFTER")
        setTimeout(function(){ local_db.check_for_recordings_to_upload() }, 5000);
      }
      else if (recording != undefined) {
        local_db.show_user_upload_details();
        local_db.upload_remaining_blobs(recording)
      } else {
        if (window.uploading) {
           console.log("still uploading so now waiting")
           setTimeout(function(){ local_db.check_for_recordings_to_upload() }, 3000);
        } else {
          // check for any blobs left over
          var blobs = await db.blobs.toArray()
          var blob = blobs[0]
          if (blob != undefined){
            db.recordings.put({ recording_id: blob["recording_id"], attendee_id: blob["attendee_id"] }).then (function(){
              console.log(`Added recording id ${blob["recording_id"]} into the local db`)
              local_db.check_for_recordings_to_upload()
            }).catch(function(error) {
              Rollbar.error("Error Saving blob:(check_for_recordings_to_upload)" + error)
              console.log ("ERROR SAVING BLOB4: " + error);
              alert("ERROR SAVING BLOB (fr): " + error);
            });
          } else {
            local_db.running = false;
            console.log("No recordings")
            $("#uploading_past_recordings, #still_uploading_local, #still_uploading").hide()
            $("#all_done_uplading").show()
          }
        }
      }
    }).catch(function(error) {
      alert("Your browser may not support saving local recordings. Please update your browser or try the Chrome browser. \n\n Error:" + error)
      console.log ("CANT GET RECORDINGS: " + error);
    });
  }

  async show_user_upload_details() {
    if (!window.uploading && $("#still_uploading_local:visible").length == 0) {
      $("#uploading_past_recordings").show()
    }

    db.blobs.count().then(function(count){
      $("#count_of_files_left_to_upload").text(count)
      $("#current_count_of_files_left_to_upload").text(count)  // room page
    })

  }

  async upload_remaining_blobs(recording){
    // console.log("upload remaining blobs")
    var recording_id = recording["recording_id"]
    var blob = await db.blobs.where({recording_id: recording_id}).first()
    if (blob != undefined){
      local_db.upload_blob(blob);
    } else {
        if (window.uploading && $("#recording_id").attr("data-recording-id") == recording_id) {
          // console.log("check again in 5 seconds since still uploading current recording id")
          setTimeout(function(){ local_db.check_for_recordings_to_upload() }, 5000);
        } else {
          console.log("time to complete")
          local_db.complete_recording(recording)
        }
    }
  }

  complete_recording(recording) {
    console.log("complete recording")
     var recording_id = recording["recording_id"]
     var attendee_id = recording["attendee_id"]


     if (typeof options !== 'undefined') {
         if (options.share_uid){
         // maybe they didnt screen share for the following recordings in the room
         Rails.ajax({url: `/recordings/${recording_id}/attendee/${options.share_uid}/finished.js`, type: "patch"})
       }
     }

     Rails.ajax({url: `/recordings/${recording_id}/attendee/${attendee_id}/finished.js`, type: "patch", success: function(){
         //delete from local
         db.recordings.where({recording_id: recording_id, attendee_id: attendee_id}).delete().then (function(){
           console.log(`deleted recording ${recording_id} from local storage`)
           local_db.check_for_recordings_to_upload()
         }).catch(function(error) {
           console.log ("ERROR DELETING BLOB: " + error);
         });
      }
    })
  }

   async upload_blob(blob){
     var filename     = blob["filename"]
     var blob_data    = blob["blob_data"]
     var attendee_id  = blob["attendee_id"]
     var recording_id = blob["recording_id"]
     if (blob_data == undefined){
       console.log("upload blobb " + blob["filename"] +" recording_id:"+ blob["recording_id"] + " attendee_id:"+ blob["attendee_id"])
       Rollbar.error("blob_data_undefined", blob)
     }
     var file = new File([blob_data], filename, {lastModified: new Date().getTime(), type: blob_data.type});

     if (file.size < 100){
       Room.show_error_message("Your browser tried to save a recording without any data. Please try a different browser like Google Chrome or else contact support at support@iris.fm.")
       console.error("Too small upload: "  + file.size)
       setTimeout(function(){
         Rails.ajax({url: `/recordings/${recording_id}/attendee/${attendee_id}/error.js?error=toosmallfileB` + file.size, type: "patch"})
       }, 1000);
     }

     console.log("upload blob " + blob["filename"] + " file size: "+file.size+" recording_id:"+ blob["recording_id"] + " attendee_id:"+ blob["attendee_id"])

    if (typeof $("").fileupload === 'undefined' ||  $('.directUpload').find("input:file").length == 0 || window.uploading_recording_id != blob["recording_id"] || window.uploading_attendee_id != blob["attendee_id"]){
      window.file_to_upload = file;
      var room_id = $("#room_id").attr("data-room-id")
      Rails.ajax({url: `/recordings/${recording_id}/attendee/${attendee_id}/finish_post_url.js?room_id=${room_id}`, type: "get",
        error: function(data){
          Rollbar.error("failed_to_get_finish_post_url")
          console.log("failed getted post url")
          console.log(blob)
          setTimeout(function(){ local_db.upload_blob(blob) }, 2000);
        },
      })
    } else {
      $('.directUpload').find("input:file").fileupload('add', {files: [file]});
    }
  }


  async show_files_to_download(){
    var total_blobs = await db.blobs.count()
    if (total_blobs > 0){
      var blobs = await db.blobs.toArray()
      blobs.forEach(function (blob, index) {
        var filename     = blob["filename"]
        var blob_data    = blob["blob_data"]
        var attendee_id  = blob["attendee_id"]
        var recording_id = blob["recording_id"]

        var file_row = `<li data-blob-id="${blob["id"]}" class="py-4">
          <div class="flex items-center space-x-4">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">${filename}</p>
              <p class="text-xs text-gray-500 truncate">Recording id: ${recording_id}</p>
            </div>
            <div>
              <a href="#" onclick="local_db.download_blobfile(event, ${blob["id"]})" class="download_file inline-flex items-center shadow-sm px-3 py-1 border border-gray-300 text-sm leading-5 hover:bg-gray-100 font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"> Download </a>
              <a href="#" onclick="local_db.delete_blobfile(event, ${blob["id"]})" class="delete_file inline-flex items-center shadow-sm px-3 py-1 border border-gray-300 text-sm leading-5 hover:bg-gray-100 font-medium rounded-lg text-red-700 bg-white hover:bg-gray-50"> Delete </a>
            </div>
          </div>
        </li>`

        $("#filelist").append(file_row)
      });
    } else {
      var file_row = `<li class="py-4">
        <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
            <p class="text-sm text-gray-900 truncate">There are no recordings to download</p>
          </div>
          <div>
          </div>
        </div>
      </li>`
      $("#filelist").append(file_row)

    }

    $("#download_files").show()
  }

  async download_blobfile(event, blob_id){
    event.preventDefault()
    var blob = await db.blobs.where({id: blob_id}).first()
    if (blob != undefined){
      var attendee_id  = blob["attendee_id"]
      var blob_data    = blob["blob_data"]
      var recording_id = blob["recording_id"]
      var fileLink = document.createElement('a');
      fileLink.download = recording_id + "_" + attendee_id + "_" + blob["filename"];
      var blob_file = new Blob([blob_data], {type: blob_data.type});
      fileLink.href = URL.createObjectURL(blob_file);
      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
    } else {
        console.log("cant find!")
        Rollbar.error("cant find blob!")
    }

  }

  async delete_blobfile(event, blob_id){
    event.preventDefault()
    if (confirm("Are you sure you want to delete this file?") == true) {
      var blob = await db.blobs.where({id: blob_id}).first()
      if (blob != undefined){
        db.blobs.where({id: blob_id}).delete().then (function(){
          $(`[data-blob-id='${blob_id}']`).remove()
        }).catch(function(error) {
          console.log ("ERROR - DELETING BLOBBB: " + error);
        });
      } else {
          console.log("cant find to delete!")
          Rollbar.error("cant find blob to delete!")
      }
    }
  }


}
